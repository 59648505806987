@media (min-width: 768px) {
  .restage-event-eventcard-container {
    padding-left: 1.458vw;
    padding-right: 1.458vw;
    padding-top: 1.51041vw;
    padding-bottom: 4.16vw;
    width: 18.229vw;
    border-radius: 9px;
  }

  .restage-event-eventcard-instagram,
  .restage-event-eventcard-linkedin {
    width: 1.92708vw;
  }

  .restage-event-eventcard-twitter,
  .restage-event-eventcard-youtube {
    width: 2.34375vw;
  }

  .restage-event-eventcard-icons {
    margin-top: 1.30208vw;
    margin-bottom: 2.60vw;
  }

  .restage-event-eventcard-details > *:not(:last-child) {
    margin-bottom: 1.04166vw;
  }

  .restage-event-eventcard-icons > *:not(:last-child) {
    margin-right: 0.625vw;
  }

  .restage-event-eventcard-logo {
    width: 9.11458vw;
    margin-bottom: 1.5625vw;
  }
}
.restage-event-eventcard-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: fit-content;
}
