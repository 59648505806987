.listitem > *:first-child {
  margin-right: 15px;
}

@media (min-width: 768px)  {

  .listitem:not(:nth-last-child(2)) {
    margin-bottom: 15px;
  }
}

.listitem:not(:last-child) {
  margin-bottom: 15px;
}
