.sponsors-floating-img {
  position: absolute;
  z-index: 2;
  right: 0;
}

.sponsors-floating-disc {
  position: absolute;
  z-index: 1;
  left: 0px;
}

@media (max-width: 767px) {

}

@media (min-width: 1130px) and (max-width: 1199px){
  .sponsors-floating-disc {
    width: 100px;
    top: 75px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px){
  .sponsors-floating-disc {
    width: 75px;
    top: 75px;
  }
}

@media (min-width: 1300px) {
  .sponsors-floating-disc {
    width: 100px;
    top: 75px;
  }
}


@media (min-width: 768px) and (max-width: 1129px) {
  .sponsors-floating-disc {
    width: 65px;
    top: 15px;
  }
}

.sponsors-floating-dot {
  position: absolute;
  z-index: 1;
  width: 80px;
  left: 10px;
  bottom: -40px;
}

.sponsors-floating-another-dot {
  position: absolute;
  z-index: 1;
  width: 75px;
  right: 0%;
  bottom: calc(50% - 75px);
}

.sponsors-label {
  margin-bottom: 15spx;
}


.sponsor-persona-tile {
  width: 200px;
  height: 200px;
  border: 3px solid #FBFBFB;
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -webkit-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -moz-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -o-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  border-radius: 28px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #FBFBFB;
}

.home-sponsors-container {
  position: relative;
  z-index: 2;
}

.sponsors-sections-container {
  max-width: 850px;
}

.home-sponsors-header {
  max-width: 600px;
}


@media (min-width: 768px) {

  .home-sponsors-container {

  }

  .home-sponsors-header {
    margin-bottom: 65px;
  }
}

@media (max-width: 575px) {
  .sponsors-sections-container > *:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {

  .home-sponsors-container {

  }

  .home-sponsors-header {
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .sponsors-sections-container {
    width: 100%;
  }

  .home-sponsors-cta {
    margin-top: 65px;
  }
}

@media (max-width: 991px) {
  .home-sponsors-cta {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-sponsors-image-container {
    margin-bottom: 35px;
  }
}

.home-sponsors-image-container > img {
  width: 200px;
}

.home-sponsors-dots {
  background-color: #3E0359;
  position: relative;
}


.actions-label {
  font-size: 40px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.actions-description {
  font-size: 20px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
@media (min-width: 1200px) {
  .sponsors-floating-img {
    width: 500px;
    top: -230px;
  }
}

@media (min-width: 768px) and (max-width: 1199px){
  .sponsors-floating-img {
    width: 500px;
    top: -125px;
  }
}


@media (min-width: 768px) {
  .home-sponsors-dots {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .actions-header {
    margin-top: 95px;
  }
}

@media (max-width: 767px) {
  .home-sponsors-dots {
    padding-bottom: 40px;
  }
  .actions-header {
    margin-top: 65px;
  }

}

@media (max-width: 767px) and (min-width: 576px) {
  .home-sponsors-dots {
    padding-top: 200px;
  }
  .sponsors-floating-disc {
    width: 100px;
    top: 15px;
  }
}

@media (max-width: 575px) {
  .sponsors-floating-disc {
    width: 50px;
    top: 15px;
  }
}

@media (max-width: 575px) and (min-width: 500px) {
  .home-sponsors-dots {
    padding-top: 250px;
  }
}

@media (max-width: 499px) {
  .home-sponsors-dots {
    padding-top: 150px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .sponsors-floating-img {
    width: 500px;
    top: -230px;
  }
}

@media (max-width: 575px) {
  .sponsors-floating-img {
    width: 100%;
    padding-left: 25px;
  }
}

@media (max-width: 575px) and (min-width: 400px) {
  .sponsors-floating-img {
    top: -230px;
  }
}


@media (max-width: 399px) {
  .sponsors-floating-img {
    top: -160px;
  }
}
