.manage-header {
  margin-bottom: 25px;
  text-align: start;
}

@media (min-width: 768px) {
  .event-main-panel {
    padding-left: 0px;
  }

  .event-right-panel {
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .event-main-panel {
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px
  }
}
