.subscribemodal-container {
  background-image: linear-gradient(to bottom, #680894, #EB5B49);
  height: 100%;
  padding: 20px;
  position: relative;
}

.subscribemodal-container {
  width: 100%;
}

.subscribemodal-bubbles-top, .subscribemodal-bubbles-right {
  position: absolute;
  z-index: 1;
  height: 350px;
}

.subscribemodal-bubbles-top {
  top: 0px;
  left: 0px;
}

.subscribemodal-bubbles-right {
  right: 0px;
  top: 0px;
}

.subscribemodal-logo {
  bottom: 50px;
  right: 50px;
  position: absolute;
}

.subscribemodal-purple {
  color: #3E0359;
}
