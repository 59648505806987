.requestsponsorship-container > *:not(:last-child) {
  margin-bottom: 25px;
}


.inputfile {
	display: none
}

.sponsorship-request-form  > *:not(:last-child) {
  margin-bottom: 25px;
}
