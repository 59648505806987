/*.home-eo-how-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 500px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 35px;
  padding-left: 35px;
}*/

@media (min-width: 992px) {
  .home-eo-how-dots {
    bottom: -135px;
  }
}

@media (max-width: 991px) {
  .how-eo-cards > * {
    margin-bottom: 50px;
  }

  .home-eo-how-wrapper {
    margin-bottom: 150px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .home-eo-how-dots {
    bottom: -295px;
    width: 190px !important;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .home-eo-how-dots {
    bottom: -295px;
    width: 120px !important;
  }
}

@media (min-width: 992px) {
  .how-eo-cards > * {
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .how-eo-cards {
    margin-left: -15px;
    margin-right: -15px;
  }

  .how-eo-cards > *:first-child {
    margin-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .home-eo-how-container {
    padding-right: 17.5%;
  }

  .home-eo-how-wrapper {
    padding-bottom: 140px;
    padding-top: 50px;
  }

  .home-eo-how-container {
    padding-bottom: 200px;
  }

  .home-eo-how-dots {
    background-position-y: 0px;
  }
}


@media (max-width: 767px) {
  .home-eo-how-container {
    padding-right: 35px;
  }

  .home-eo-how-wrapper {
    padding-bottom: 70px;
    padding-top: 125px;
  }

  .home-eo-how-dots {
    background-position-y: 40px;
  }
}

@media (min-width: 576px) {
  .home-eo-how-container {
    padding-bottom: 250px;
  }
  .home-eo-how-dots {
    width: 240px;
  }
}


@media (max-width: 575px) {
  .home-eo-how-dots {
    width: 80px;
  }

  .home-eo-how-dots {
    bottom: -60px;
  }

  .home-eo-how-container {
    padding-bottom: 350px;
  }
}

@media (max-width: 575px) and (min-width: 400px) {
  .home-eo-how-container {
    padding-bottom: 350px;
  }
}

@media (max-width: 399px) {
  .home-eo-how-container {
    padding-bottom: 175px;
  }
}

.home-eo-how-dots {
  position: absolute;
  z-index: 1;
  left: 0px;
}

.home-eo-how-wrapper {
  position: relative;
}
