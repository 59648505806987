.postauthor-container {
  padding: 40px;
  background-color: #F2F2F2;
  border-radius: 9px;
}

.postauthor-socialurl {
  cursor: pointer;
  text-decoration: underline;
}

.postauthor-img {
  height: 75px;
  width: 75px;
  border-radius: 100%;
  background-size: 75px;
  background-position-y: -1px;
}

@media (min-width: 992px) {
  .postauthor-cta {
    border-left: 1px solid #333333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .postauthor-cta-description {
    max-width: 285px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .postauthor-cta-description {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
