@media (min-width: 768px) {
  .restage-navbar {
    height: 85px;
    width: 100%;
    background-color: #680894;
    padding-left: 75px;
    padding-right: 45px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }


  .restage-logo {
    height: 48px;
    margin-right: 65px;
  }

  .restage-user-link {
    height: 45px;
    float: right;
  }

  .restage-user-link-container {
    width: 100%;
  }

  .restage-user-link-container:hover {
    cursor: pointer;
  }

  .restage-hamburger {
    display: none;
  }

  .restage-sidenav {

  }

  .restage-sidenav-contents > *:not(:last-child) {
    margin-bottom: 25px;
  }

  .restage-sidenav {
    width: 300px;
    height: 315px;
  }
}

@media (max-width: 767px) {

  .restage-navbar {
    height: 85px;
    width: 100%;
    background-color: #680894;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .restage-logo {
    height: 48px;
    margin-right: 65px;
  }

  .restage-user-link {
    height: 45px;
    float: right;
  }

  .restage-user-link-container {
    display: none;
  }

  .restage-sidenav {
    width: 100%;
    height: 270px;
    background-color: #680894;
  }

  .restage-sidenav-contents > *:not(:last-child) {
    margin-bottom: 25px;
  }
}

.restage-sidenav {
  right: 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px ;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px ;
  box-shadow:  rgba(0, 0, 0, 0.1) 0px 0px 5px ;
  overflow: hidden;
  background-color: #680894;
  z-index: 10;
  position: absolute;
  -webkit-transition-property: height, width;
   -moz-transition-property: height, width;
   -moz-transition-property: height, width;
     -o-transition-property: height, width;
        transition-property: height, width;
  -webkit-transition-duration: 500ms;
   -moz-transition-duration: 500ms;
   -moz-transition-duration: 500ms;
     -o-transition-duration: 500ms;
        transition-duration: 500ms;
}

.restage-sidenav-hidden {
  height: 0% !important;
}

.restage-sidenav-contents {
  padding: 25px;
}
