.gallery-container {
  position: relative;
  width: 100%;
  /*background-color: #680894;*/
  z-index: 0;
  height: 100%;
  /*border-top-left-radius: 9px;
  border-top-right-radius: 9px;*/
  border-radius: 9px;
  overflow: hidden;
}

.gallery-dots > *:not(:last-child) {
  margin-right: 5px;
}

.gallery-dot {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #FFFFFF;
}

.gallery-dot-active {
  background-color: #680894;
  -webkit-transition-property: color;
   -moz-transition-property: color;
     -o-transition-property: color;
        transition-property: color;
  -webkit-transition-duration: 250ms;
   -moz-transition-duration: 250ms;
     -o-transition-duration: 250ms;
        transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
     -moz-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
}

/*
.gallery-image {
  width: 100%;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
*/

.gallery-image {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.gallery-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

.gallery-dots {
  position: absolute;
  bottom:  30px;
  width: 100%;
}

.gallery-button:disabled {
    cursor: not-allowed;
}

.gallery-button {
  width: 35px;
  height: 35px;
  z-index: 2;
  background-color: #FFFFFF;
  border: none;
  opacity: .35;
  cursor: pointer;
  border-radius: 100%;
  padding: 0px !important;
  position: absolute;
}

.gallery-button-left {
  left: 0px;
  position: absolute;
}

.gallery-button-right {
  right: 0px;
  position: absolute;
}

.gallery-button-left > button {
  left: 20px;
}

.gallery-button-left > img {
  left: 30px;
}

.gallery-button-right > button {
  right: 20px;
}


.gallery-button-right > img {
  right: 30px;
}

.gallery-button-left, .gallery-button-right, .gallery-swipeableviews, .gallery-image-container {
  height: 100%;
}

.gallery-caret {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.react-swipeable-view-container {
  height: 100%;
}

.react-swipeable-view-container > *:not(:last-child) {
  background-color: transparent;
}
