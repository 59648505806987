.eventreview-container > *:not(:last-child) {
  margin-bottom: 50px;
}

.eventreview-details {
  padding-top: 15px;
  padding-bottom: 30px;
}

/*
@media (max-width: 767px) {
  .review-row > *:first-child {
    margin-bottom: 15px;
  }
}*/


@media (min-width: 768px) {
  .eventreview-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .eventreview-image {
    width: 100%;
  }
}

.eventpage-body-packages > *:not(:last-child) {
  margin-bottom: 30px;
}
