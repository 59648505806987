.create-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.create-reasons > *:not(:last-child) {
  margin-bottom: 15px;
}

.create-container {
  padding-left: 0px !important
}

.create-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 768px) {
  .create-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .create-content {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
  .create-container {
    padding-top: 25px !important;
    padding-bottom: 0px;
    padding-right: 45px;
    padding-top: 0px !important;
  }

}

@media (max-width: 767px) {
  .create-image {
    display: none;
  }
  .create-content {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
  }

  .create-container {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-top: 0px !important;
    padding-right: 0px !important;
  }

  .create-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.create-confirmation-image {
  width: 100%;
  max-width: 250px;
  margin-bottom: 30px;
}
