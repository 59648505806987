.card-img {
  height: 75px;
  margin-bottom: 15px;
}

.card-label {
  margin-bottom: 8px;
}

.card-description {

}

.card-container {
  padding-left: -15px;
  padding-right: -15px;
}


@media (min-width: 992px) {
  .card-container {
    width: calc(6/12 * 100% - 30px);
  }
}
