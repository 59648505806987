
@media (max-width: 991px) and (min-width: 768px) {
  .sponsor-portfolio-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }

}

@media (max-width: 767px) {
  .sponsor-portfolio-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .sponsorhome-container {
    padding-top: 30px;
    padding-bottom: 65px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .sponsorhome-container {
    padding-bottom: 65px;
  }
}
@media (max-width: 767px) {
  .sponsorhome-container {
    padding-top: 30px;

    padding-bottom: 35px;
  }
}
