.accordion-container {
  width: 100%;
  border-radius: 5px;
}

.accordion-panel {
  /*background-color: #333333;*/
  background-color: white;
}

.accordion-container > .accordionpanel-container:first-child > .accordionpanel-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion-container > .accordionpanel-container:last-child > .accordionpanel-header {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion-container > .accordionpanel-container:last-child > .accordionpanel-body {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid #BECAD6;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #E3DBE6 !important;
}

.ant-collapse > .ant-collapse-item:first-child{
    /*border-top: 1px solid #E3DBE6 !important;*/
}

.ant-collapse-content {
    border-top: none !important;
}

.ant-collapse {
    border: none !important;
    background-color: transparent !important;
}

.ant-collapse-header > i {
  color: #680894;
}
