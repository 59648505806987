.eventtile-prototype-poster-container {
  padding: 0px !important;
}

.eventtile-prototype-header {

}

.eventtile-prototype-poster {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.eventtile-prototype-container:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

}

.eventtile-prototype-container {
  border-radius: 5px;
  -webkit-transition: box-shadow .5s;
  -o-transition: box-shadow .5s;
  -moz-transition: box-shadow .5s;
  transition: box-shadow .5s;
  border: 1px solid #BECAD6;
  overflow: hidden;
}

@media (min-width: 992px) {
  .eventtile-prototype-container {
    width: calc(12/12 * 100% - 0px) ;
    padding-left: -15px;
    padding-right: -15px;
  }

  /*.eventtile-container > * {
    padding-left: 15px;
    padding-right: 15px;
  }*/
}

@media (min-width: 768px) and (max-width: 991px) {
  .eventtile-prototype-container {
    width: calc(12/12 * 100% - 0px);
  }
}

@media (max-width: 767px) {
  .eventtile-prototype-container {
    width: 100%;
  }
}

.eventtile-prototype-container:hover {
  cursor: pointer;
}

.eventtile-prototype-footer {
  padding: 20px;
}

@media (min-width: 576px) {
  .eventtile-prototype-container {

  }
  .eventtile-prototype-footer {
    height: 200px;
  }

}

@media (max-width: 575px) {
  .eventtile-prototype-container {

  }
}
