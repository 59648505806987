.tableofcontents-container {
  background-color: #F2F2F2;
  border-radius: 9px;
}

.tableofcontents-link:hover {
  cursor: pointer;
}

.tableofcontents-index {
  width: 15px;
}

@media (min-width: 768px) {
  .tableofcontents-index {
    margin-right: 20px;
  }

  .tableofcontents-container {
    padding: 30px 90px 30px 90px;
  }
}

@media (max-width: 767px) {
  .tableofcontents-index {
    margin-right: 15px;
  }

  .tableofcontents-container {
    padding: 15px;
  }
}

.tableofcontents-header, .tableofcontents-item:not(:last-child) {
  margin-bottom: 10px;
}

.tableofcontents-link:hover {
  color: #3E0359;
}
