@media (min-width: 992px) {
  .detail-start {
    text-align: start;
  }

  .detail-end {
    text-align: end;
  }
}

@media (max-width: 991px) {
  .detail-start {
    text-align: center;
  }

  .detail-end {
    text-align: center;
  }
}

.detail-detail {
  font-family: 'Poppins', sans-serif;
  line-height: normal;
  font-size: 24px;
  color: #FBFBFB;
}

.detail-detail-position {
  margin-bottom: 20px;
}

.detail-description {
  font-family: 'Montserrat Alternates', sans-serif;
  line-height: normal;
  font-size: 16px;
  color: #FBFBFB;
}

.detail-white {
  color: #FFFFFF;
}

.detail-black {
  color: #2C2E30;
}

@media (min-width: 768px) {
  .detail-btw {
    color: #2C2E30;
  }

  .detail-wtb {
    color: white;
  }
}

@media (max-width: 767px) {
  .detail-wtb {
    color: #2C2E30;
  }

  .detail-btw {
    color: white;
  }
}
