.getstarted-container > *:not(:last-child) {
  margin-bottom: 35px;
}

.getstarted-reasons > *:not(:last-child) {
  margin-bottom: 15px;
}

.getstarted-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.getstarted-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 768px) {
  .getstarted-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .getstarted-content {
    padding: 20px;
    padding-right: 0px !important;
  }
  .getstarted-container {
    padding-top: 25px !important;
    padding-bottom: 50px;
  }

}

@media (max-width: 767px) {
  .getstarted-content {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .getstarted-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .getstarted-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
