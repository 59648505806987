.resources-hero-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-top: 100px;
  padding-bottom: 100px;
  /* background-image: linear-gradient(to bottom, #680894, #EB5B49) with 70% opacity */
  background-image: linear-gradient(to bottom, rgba(104, 8, 148, .7), rgba(235, 91, 73, .7));
}

@media (min-width: 992px) {
  .resources-hero-container {
    height: calc(100vh - 70px);
    min-height: 600px;
  }
}

@media (max-width: 991px) {
  .resources-hero-text {
    margin-bottom: 50px;
  }
}


.resources-hero-button-container {
  margin-top: 60px;
}

.resources-dots-left, .resources-dots-right {
  position: absolute;
  z-index: 1;

}

.resources-dots-left {
  bottom: 0px;
  left: 0px;
}

.resources-dots-right {
  right: 0px;
  top: 0px;
}

.resources-hero-wrapper {
  max-width: 1200px;
  z-index: 2;
}

@media (max-width: 575px) {
  .resources-dots-left, .resources-dots-right {
    width: 100%;
  }
}
