.shared-emails-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.share-link {
  margin-top: 30px;
}

.share-link:hover {
  cursor: pointer;
}

.share-event-modal-container {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.share-event-modal-divider-1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.share-event-modal-divider-2 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.shared-link {
  margin-top: 15px;
}
