

.audienceprofile-attributes-wrapper {
  background-color: #E3DBE6;
}

@media (min-width: 768px) {
  .audienceprofile-attributes-wrapper {
    padding: 25px;
    height: 100%
  }
}

@media (max-width: 767px) {
  .audienceprofile-attributes-wrapper {
    padding: 15px;
  }
}
