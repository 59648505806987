.herowithimage-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white;
}

@media (min-width: 992px) {
  .herowithimage-container {
    height: 100vh;
    min-height: 600px;
  }
}

@media (max-width: 991px) {
  .herowithimage-image-container {
    margin-top: 50px;
  }
}


.learnmore-hero-button-container {
  margin-top: 60px;
}

.herowithimage-header, .herowithimage-description {
  text-align: start;
  line-height: 32px;
}

/*
.hero-section-override {
  padding-left: 0px !important;
  padding-right: 0px !important;
}*/
