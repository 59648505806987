.upload-container {
  border: 1px dashed #4F4F4F;
  width: 100%;
  background-color: #F2F2F2;
  height: 115px;
  border-radius: 2px;
}

.upload-container:hover {
  cursor: pointer;
}
