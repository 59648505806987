
.expt-playlistNav-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 1;
  opacity: 0;
  padding-left: 1.8229vw;
  padding-right: 1.8229vw;
  /*padding-bottom: 3.59375vw;*/
  padding-top: 1vw;
  padding-bottom: 1vw;
  transition-duration: .25s;
  transition-property: opacity;
  /*background-color: rgba(62,3,89,.67)*/
}


.expt-playlistNav-container:hover {
  opacity: 1;
}
