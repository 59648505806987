.postmediamobile-icons > *:not(:last-child) {
  margin-right: 30px;
}

.postmediamobile-container {
  padding: 30px;
}

.postmediamobile-icon {
  cursor: pointer;
  color: #F8A59A;
}

@media (min-width: 992px) {
  .postmediamobile-container {
    display: none;
  }

}
