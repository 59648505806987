.radiobutton-label {
  margin-left: 10px;
  margin-bottom: unset;
  color: #333333;
}

.radiobutton-description {
  padding-left: 35px;
}

.radiobutton-container:hover {
  cursor: pointer;
}

.radiobutton-label:hover {
  cursor: pointer;
}

.radiobutton-inner.checkbox-open {
 transform: scale(.9);
}

.radiobutton-inner {
   background-color: #680894;
   border-radius: 100%;
    height: 100%;
    width: 100%;
  -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    -moz-transition-property: transform;
    -ms-transition-property: transform;
    -o-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .30s;
    -moz-transition-duration: .30s;
    -ms-transition-duration: .30s;
    -o-transition-duration: .30s;
    transition-duration: .30s;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -ms-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-delay: initial;
    -moz-transition-delay: initial;
    -ms-transition-delay: initial;
    -o-transition-delay: initial;
    transition-delay: initial;
}

.radiobutton-outer {
  height: 25px;
  width: 25px;
  border: 1px solid #680894;
  border-radius: 100%;
}
