.button {
  color: white;
  background-color: #7F7882;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-family: 'Montserrat',sans-serif;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
}

.button-right-rounded {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.button:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .button-lg {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .button-lg {
    width: 100%;
  }
}

.button-xs {
  height: 25px !important;
  min-height: 25px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
