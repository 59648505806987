@media (min-width: 768px) {
  .learnmore-resources-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .blogposts-container > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .blogposts-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .blogposts-container > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .blogposts-container {
    margin-left: -15px;
    margin-right: -15px;
  }

  .learnmore-resources-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.learnmore-resources-parts > *:not(:last-child) {
  margin-bottom: 25px;
}

.learnmore-resources-accordion-panel > .ant-collapse-content, .learnmore-resources-accordion-panel {
  background-color: transparent !important;
}

@media (max-width: 991px) {
  .resources-ctas {
    margin-top: 35px;
  }
}

.learnmore-resources-accordion-panel > .ant-collapse-header {
  background-color: #E3DBE6;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.learnmore-resources-accordion-panel > .ant-collapse-content {
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}


.learnmore-resources-accordion-panel:first-child > .ant-collapse-header {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

@media (min-width: 576px) {
  .resources-create-cta {
    margin-right: 15px;
  }
}


@media (max-width: 575px) {
  .resources-create-cta {
    margin-bottom: 15px;
  }
}
