.audienceprofile-container {

}

.audienceprofile-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.audienceprofile-distance > *:first-child {
  margin-right: 15px;
}

.audienceprofile-distance {
  margin-top: 10px !important;
}

.audienceprofile-distance-hidden {
  display: none;
}
