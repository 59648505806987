@media (min-width: 768px) {
  .restage-wrapper {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

@media (max-width: 767px) {
  .restage-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.restage-wrapper {
  
}
