@media (min-width: 768px) {
  .tabgroup-container > * {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .tabgroup-container {
    width: 100%;
  }
}

@media (max-width: 767px) and (min-width: 400px) {
  .tabgroup-container > * {
    padding-right: 10px;
    padding-left: 10px;
  }

}

@media (max-width: 399px) {
  .tabgroup-container > * {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.tabgroup-container {

}
