.edit-modal-container > *, .edit-audience-modal-general-tab > * {
  margin-bottom: 25px;
}

.edit-modal-container {

  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  max-width: 1200px;
}

.edit-modal-wrapper {
  height: 100%;
  overflow-y: scroll;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 845px) {
  .edit-attendee-button {
    padding-bottom: 15px;
  }
}
