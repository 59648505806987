.ant-select-search__field, .ant-select-dropdown-menu-item {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-select-dropdown-menu-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.autocomplete-container {
  position: relative;
  z-index: 1;
}

.autocomplete-options {
  position: absolute;
  width: 100%;
  box-shadow: 0 3px 28px 0 rgba(0,0,0,0.18);
  z-index: 1;
}

.autocomplete-option {
  background-color: #FFFFFF;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  width: 100%;
  min-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.autocomplete-option:hover {
  cursor: pointer;
  background-color: #F2F2F2
}

.autocomplete-hidden {
  display: none;
}

.textfield-error {
  color: red;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

.textfield-error-container {
  position: absolute;
}

.textfield-hidden {
  display: none;
}

.ant-input {
  border-radius: 4px !important;
}
