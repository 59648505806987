.pill-container  {
  border-radius: 20px;
  min-width: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #680894;
}

.pill-close-x {
  fill: #FBFBFB;
}
.pill-close {
  margin-left: 15px;
}
