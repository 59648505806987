.benefit-label {
  line-height: normal;
  font-size: 40px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #333333;
}

.benefit-label-position {
  margin-bottom: 50px;
}

.benefit-description {
  font-family: 'Montserrat', sans-serif;
  line-height: 34px;
  font-size: 20px;
  text-align: center;
  color: #333333;
}

.benefit-container {
  padding-top: 80px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .benefit-image-container {
    padding-top: 50px;
  }
}

.benefit-image {
  height: 100%;
  width: 100%;
}
