.modal-container {
  position: fixed;
  background-color: rgba(0, 0, 0,.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  overflow-y: scroll;
}

.modal-contents {
  min-height: 100%;
  width: 100%;
}

.modal-wrapper {
  background-color: white;
}

.restage-main-modal, .restage-main-modal-lg {
  top: unset !important;
  display: flex;
}

.restage-main-modal-lg {
  padding-bottom: 0px !important;
  height: 100%;
}

.restage-antd-modal-wrapper-full {
  padding: 0px !important;
}


@media (min-width: 768px) {
  .restage-antd-modal-wrapper {

  }

/*
  .restage-main-modal > .ant-modal-content {
    height: 100% !important;
  }*/


  .restage-antd-modal-wrapper-lg {

  }

  .modal-container {
    padding-top: 90px
  }

  .restage-modal-header {
    height: 90px;
  }

  .modal-container, .modal-contents {
    padding-bottom: 90px;
    padding-left: 90px;
    padding-right: 90px;
  }

  .ant-modal-content {
    /*padding-bottom: 60px !important;*/
    /*padding: 60px !important;*/
  }
  .ant-modal {

    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

.ant-modal-content {
  flex-shrink: 0!important;
  width: 100%;
}

@media (max-width: 767px) {
  .ant-modal-content {
    /*padding: 60px !important;*/
  }

  .ant-modal {

    padding-top: 60px;
  }

  .restage-antd-modal-wrapper {

  }

  .restage-antd-modal-wrapper-lg {

  }

  .restage-modal-header {
    height: 50px;
  }

  .modal-container {
    padding-top: 50px;
  }

  .modal-container, .modal-contents {
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.modal-hidden {
  display: none;
}

.restage-modal-header {
  border: none;
  position: relative;
}

.modal-close {
  margin-right: 1rem;
  margin-top: 1rem;
  right: 0;
  position: absolute;
}

.restage-modal-footer {
  min-height: 50px;
}

.ant-modal-content {
  background-color: unset !important;
  margin-bottom: 60px;
  /*border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;*/
}

.ant-modal-body {
  height: 100% !important;
  padding: 0px !important;
  background-color: white !important;
  border-top-left-radius: 9px !important;
  border-top-right-radius: 9px !important;

}

.ant-modal-footer {
  /*position: absolute;*/
  width: 100%;
  bottom: 0;
  background-color: #F2F2F2 !important;
  /*z-index: 1;*/
}

/*
.restage-main-modal-sm. > .ant-modal-content {
  height: unset !important;
}*/

.restage-main-modal-xs > .ant-modal-content {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.restage-main-modal-full > .ant-modal-content {
  height: 100%;
  width: 100%;
  margin: 0 !important;
}

.restage-main-modal-full {
  width: 100%;
  max-width: 100% !important;
  padding: 0px !important;
  height: 100%;
  top: 0px !important;
  margin: 0px !important;
}


.restage-main-modal-full > .ant-modal-content > .ant-modal-body {
  border-radius: 0px !important;
}

.ant-modal-wrap {

}
/*
.restage-main-modal-sm. > .ant-modal-content > .ant-modal-body {
  height:  !important;
}*/



@media (min-width: 768px) {
  .ant-modal-close {
    top: 40px !important;
    right: 40px !important;
  }
}

@media (max-width: 767px) {
  .ant-modal-close {
    top: 15px !important;
    right: 15px !important;
  }
}

.ant-modal-close-x {
  height: unset !important;
  width: unset !important;
  line-height: unset !important;
}

.ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 0px !important;
}

.modal-close-background-dark-gray {
  background-color: rgba(0, 0, 0, .35);
}

.modal-close-background {
  padding: 10px;
  border-radius: 100%;
}
