.manage-header {
  margin-bottom: 25px;
  text-align: start;
}


.manage-avatar {
  border-radius: 100%;
}

.manage-me {
  background-color: #3E0359;
}

.manage-them {
  background-color: #EB5B49;
}

.manage-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: white;
}

@media (min-width:  768px) {
  .manage-label {
    font-size: 28px;
  }

  .manage-container  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .manage-avatar {
    height: 90px;
    width: 90px;
    margin-right: 30px;
  }

  .manage-message {
    padding-right: 30px;
  }
}

@media (max-width:  767px) {
  .manage-label {
    font-size: 16px;
  }

  .manage-container  {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .manage-avatar {
    height: 45px;
    width: 45px;
    margin-right: 15px;
  }
}

.manage-button-group > *:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 575px) {
  .manage-offer-header > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .manage-offer-details {
    margin-top: 30px;
  }
}

@media (min-width: 576px) {
  .manage-offer-details {
        margin-left: 30px;
  }
}
