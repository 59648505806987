.postmediasticky-container {
  left: 5%;
  width: 70px;
  padding-top: 100px;
  position: absolute;
}

.postmediasticky-container > *:not(:last-child) {
  margin-bottom: 30px;
}

.postmediasticky-icon {
  cursor: pointer;
  color: #F8A59A;
}
