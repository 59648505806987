.ant-select-selection__placeholder {
  color: #6c757d !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  background-color: #FFFFFF;
  font-weight: 500;

}

.ant-select-selection {
  border-radius: 4px !important;
}
