.admin-events-panel {
  background-color: white;
  padding-left: 40px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.admin-events-panel-container > *:not(:last-child) {
  border-bottom: 1px solid #e3dbe6  !important;
}
