@media (min-width: 768px) {
  .expt-subscribe-modal-playlist-card {
    width: 34vw;
  }

  .expt-modal-footer {
    margin-top: 6.92708vw;
  }

  .expt-modal-footer-gutter {
    margin-right: 5.2083vw;
  }

  .expt-modal-copyright {
    width: 100%;
    padding-bottom: 2.656vw;
  }
}



.expt-subscribe-modal {
  padding-left: 3.17vw;
  padding-right: 6.67vw;
  padding-top: 8.75vw;
  padding-bottom: 2.66vw;
}

.expt-subscribe-modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.expt-subscribe-wrapper {
  z-index: 3;
  position: relative;
}

.expt-subscribe-modal-inactive {

}

.expt-brandIconUsers {
  object-fit: contain;
  width: 11.2vw;
  position: relative;
  top: 4.75vw;
  z-index: 2;
}

#transparentBanner {
  width: 100%;
  height: 31.25vw;
  position: absolute;
  top: 16.66vw;
  background-color: rgba(255,255,255,.25);
  z-index: 2;
}

.expt-modal-email-label {
  margin-top: 1.0416vw;
  margin-bottom: 1.0416vw;
}

.expt-modal-social-icons > *:not(:last-child) {
  margin-right: 1.04166vw;
}

.expt-modal-playlist-card {
  margin-bottom: 3.125vw;
}

.expt-modal-playlist-header {
  margin-bottom: 2vw;
}

.expt-modal-top-header {
  margin-bottom: 1.0416vw;
}

.expt-modal-textfieldbuttongroup {
  border-radius: 4px;
  height: 3.333333vw;
}

.expt-subscribe-linkedin-logo,
.expt-subscribe-instagram-logo {
  width: 3.3333vw;
}

.expt-subscribe-twitter-logo {
  width: 4.06vw;
}

.expt-subscribe-youtube-logo {
  width: 4.84375vw;
}

.expt-subscribe-youtube-logo,
.expt-subscribe-twitter-logo,
.expt-subscribe-linkedin-logo,
.expt-subscribe-instagram-logo {
  object-fit: contain;
}
