.restage-pdf-link {
  margin-bottom: 10px;
}

.restage-pdf-section {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .pdf-search-group {
    width: 50%
  }
}

@media (max-width: 767px) {
  .pdf-search-group {
    width: 100%;
    margin-top: 15px;
  }
}
