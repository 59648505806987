.event-audience-tab-genders > *:not(:last-child) {
  margin-bottom: 25px;
}

.event-audience-tab-details > :not(:last-child) {
  margin-bottom: 50px;
}


.event-audience-tab-attendees > *:not(:last-child) {
  margin-right: 10%;
}
