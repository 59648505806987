.eventdetails-sidepanel-container {
  margin-top: 25px;
}

.eventdetails-tilepanel > *:not(:last-child){
  margin-bottom: 15px;
}

.eventdetails-tilepanel {
  margin-top: 15px;
}

.eventdetails-tile-footer {
  padding: 15px;
}

.eventdetails-tile-container {
  border: 1px solid #BECAD6;
  border-radius: 9px;
  overflow: hidden;
}

.eventdetails-sidepanel-image {
  margin-right: 15px;
}
