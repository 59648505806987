.restage-select {
  background: #FBFBFB !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #000000 !important;
  border-radius: 9px !important;
  width: 100%;
}


.restage-select-purple > .ant-select-selection {
  border-color: #680894 !important;
}


.restage-select-purple > .ant-select-selection > .ant-select-selection__rendered > .ant-select-selection__placeholder {
  color: #680894 !important;
}
