.learnmore-eventorganizer-header {
  width: 100%;
  background-color: white;
}

/*
@media (min-width: 768px) {
  .learnmore-event-organizer-hero-container {
    background-image: url("./assets/hero.svg");
  }
}

@media (max-width: 767px) {
  .learnmore-event-organizer-hero-container {
    background-image: url("./assets/hero-mobile.svg");
  }
}*/

.learnmore-event-organizer-hero-container {
  background-color: white;
}

.eventorganizer-band {
  /*background-image: linear-gradient(to top right, #F8A59A, #EB5B49);*/
  background: linear-gradient(85deg, #680894, 65%, #E3DBE6);
  min-height: 190px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.eventorganizer-create-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.eventorganizer-dots-left {
  left: 0px;
  position: absolute;
  top: 63%;
  z-index: 2;
}

.eventorganizer-dots-right {
  right: 0px;
  position: absolute;
  top: 25%;
}

@media (min-width: 992px) {
  .learnmore-eventorganizer-header {
    padding-top: 100px;
  }

  .eventorganizer-create-img {
    max-width: 50%;
    width: 100%;
    max-height: 600px;
  }
}

@media (max-width: 991px) {
  .eventorganizer-create-img {
    margin-top: 75px;
  }

  .learnmore-eventorganizer-header {
    padding-top: 150px;
  }

  .eventorganizer-dots-left {
    height: 500px;
  }

  .eventorganizer-dots-right {
    height: 300px;
  }
}


@media (max-width: 767px) and (min-width: 576px) {
  .learnmore-eventorganizer-create-container {
    min-height: 800px;
  }
}

@media (max-width: 575px) {
  .learnmore-eventorganizer-create-container {
    min-height: 700px;
  }

  .eventorganizer-band > *:first-child {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .learnmore-eventorganizer-create-container {
    height: 600px
  }
}

@media (max-width: 1199px) and (min-width: 992px){
  .learnmore-eventorganizer-create-container {
    height: 400px
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .learnmore-eventorganizer-create-container {
    min-height: 650px;
  }

  .eventorganizer-create-img {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .eventorganizer-pagesegments-container > *:first-child {
    padding-bottom: 0px !important;
  }

  .learnmore-eventorganizer-create-container {
    padding-top: 100px;
  }

  .eventorganizer-create-img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .eventorganizer-pagesegments-container {
    padding-top: 70px;
  }
}

.eventorganizer-pagesegments-container {
  background-color: white;
}
