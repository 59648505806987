.expt-home-hero-dot {
  border-radius: 100%;
  border: none;
}

.expt-home-hero-dot:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .expt-home-hero-dot {
    width: 0.83333vw;
    height: 0.83333vw;
  }
}

.restage-home-hero-dot-inactive {
  border: 1px solid #E3DBE6;
}
