/* TODO:  add parameters for style attributes into code. */

.lineMenu {
  width: 69px;
  height: 48px;
  display: flex;
  align-items: flex-start;
  margin-top: auto;
  padding: 40px;
}

.menuLine1 {
  width: 3.6vw;
}

.menuLine2 {
  width: 1.8vw;
  top: 1.08vw;
}

.menuLine3 {
  width: 2.4vw;
  top: 2.16vw;
}

.menuLine1, .menuLine2, .menuLine3 {
  height: .3vw;
  border-radius: 3px;
  background-color: #fff;
}

.menuLine2, .menuLine3 {
  position: absolute;
}

.menuLine2.active {
  left: calc(100% - 1.8vw);
}

.menuLine2, .menuLine3 {
  left: 0%;
  transition-property: left;
  transition-duration: .2s;
}

.menuLine3.active {
  left: calc(100% - 2.4vw);
}

/*.menuLine2.active:hover {
  width: 0%;
}*/

.menuLine2 {
  left: 0%;
}
.expt-menuLines {
  position: relative;
}

.expt-menuLines:hover {
  cursor: pointer;
}
