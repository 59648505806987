.restage-background-transparent > .ant-collapse-content {
  background-color: transparent;
}

@media (min-width: 768px) {
  .restage-package-contents > * {
    margin-bottom: .78vw;
  }

  .restage-package-contents {
    padding-left: .78vw;
  }
}
