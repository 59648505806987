.signup-button {
  border-radius: 4px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  /*background-image: linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: -webkit-linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: -moz-linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: linear-gradient(to bottom right, #9B4CBF, #680894);
  background-image: -o-linear-gradient(to bottom right, #9B4CBF, #680894);*/
}

.signup-textfield {
  border-radius: 4px !important;
}

.signup-confirmation {
  display: none;
}

.signup-confirmation.signup-confirmation-success {
  display: unset !important;
}

.signup-confirmation, .signup-confirmation.signup-confirmation-success {
  position: relative;
}

.signup-success {
  position: absolute;
  color: #0cd80c;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 768px) {
  .signup-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .signup-image {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .signup-button {
    margin-left: 8px;
  }
  .signup-textfield {
    width: 270px;
  }
}

@media (max-width: 575px) {
  .signup-button {
    margin-top: 8px;
  }
}

.signup-error-message {
  /*color: rgb(104, 8, 148);*/
  color: red;
  padding-top: 3px;
}
