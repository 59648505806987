.icon {
  cursor: pointer;
}

.social {
  height: 24px;
}

.hammer {
  height: 60px;
}
