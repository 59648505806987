@media (min-width: 768px) {
  .restage-event-eventinfo-container {
    padding-bottom: 2.96875vw;
    padding-top: 4.1666vw;
    padding-left: 4.2708vw;
    padding-right: 4.2708vw;
  }

  .restage-packages-accordion {
    padding-left: 2.083vw;
    padding-right: 5.4687vw;
    width: 100%;
  }

  .restage-packages-container > *:not(:last-child) {
    margin-bottom: 2.08333vw;
  }
}

.restage-packages-container {
  width: 100%;
}
