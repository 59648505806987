@media (min-width: 768px) {
  .restage-dot {
    width: 1.17130vw;
    height: 1.17130vw;
  }
}

.restage-dot {
  border: none;
  border-radius: 100%;
  transition-property: background-color;
  transition-duration: .5s;
}

.restage-dot:hover {
  cursor: pointer;
}
