

.searchfilters-agerange > *:not(:last-child) {
  margin-bottom: 15px;
}

@media (min-width: 768px ) {
  .searchfilters-container > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px ) {
  .searchfilters-container > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .searchfilters-container {
    padding-bottom: 15px;
  }
}
