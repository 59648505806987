.how-section-img {
  height: 75px;
}

.how-section-container {
  z-index: 2;
  position: relative;
  width: 225px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  height: 285px;
}

.how-section-header {
  padding-right: 15px;
}

.how-section-divider {
  background-color: #EB5B49;
  height: 1px;
  border: none;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 35px;
}

.how-section-icon-container {
  height: 30px;
  width: 30px;
  background-color: #EB5B49;
  border-radius: 100%;
}


.how-section-icon {
  height: 20px;
}
