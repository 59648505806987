.portfolioreveiw-section > *:not(:last-child) {
  margin-bottom: 50px;
}

.portfolioreview-details > *:not(:last-child) {
  margin-bottom: 15px;
}

.portfolioreview-eyebrow {
  margin-bottom: 15px;
}

.portfolio-recommendation-row > * {
  padding: 0px !important;
}

.portfolio-recommendations-container > *:not(:last-child) {
  margin-bottom: 25px;
}
