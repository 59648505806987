.manage-header {
  margin-bottom: 25px;
  text-align: start;
}

.edit-overview-tab  > *:not(:last-child){
  margin-bottom: 50px;
}

.edit-progress {
  width: 100%;
  height: 200px;
  background-position-x: calc(100% + 30px);
  background-position-y: 10px;
  padding: 10px;
  background-size: 180px;
  background-image: url("./assets/check.svg");
  background-repeat: no-repeat;
  background-color: rgba(235,91,73, .1);
  border-radius: 4px;
  margin-bottom: 35px;
}

.edit-progress-steps > *:not(:last-child){
  margin-right: 15px;
}

.edit-progress-step {
  margin-right: 10px;
}

.edit-modal-open:hover {
  cursor: pointer;
}

.edit-modal-open {
  float: right;
}

.event-overview-documents {
  padding: 35px;
  padding-bottom: 60px !important;
  padding-top: 15px !important;
  background-color: #F8F8F8;
  width: 100%;
}

.event-overview-document-row {
  border-bottom: 1px solid #BDBDBD;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%
}

.edit-tab-pencil {
  position: absolute;
  z-index: 1;
}

@media (min-width: 768px) {
  .edit-tab-pencil {
    right: 40px;
    top: 30px;
  }
}

@media (max-width: 767px) {
  .edit-tab-pencil {
    right: 15px;
    top: 30px;
  }
}
