.hero-profile {
  max-height: 100%;
  max-width: 100%;
}

.hero-img {
  max-width: 300px;
  width: 100%;
  margin-bottom: 20px;
}

.hero-content {
  height: 100%;
}

.hero-main {
  height: calc(100% - 83px);
}

.hero-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-bottom: 25px;
  padding-top: 35px;
}


@media (min-width: 992px) {
  .hero-container {
    height: 100vh;
    min-height: 600px;
  }
}

@media(max-width: 991px) {
  .home-eo-how-dots {
      bottom: -135px;
  }

  .hero-cta-container {
    margin-top: 65px !important;
    margin-bottom: 35px;
    padding: 0px !important;
  }
}

.hero-sidedots {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -250px;
}

@media (min-width: 768px) {
  .hero-sidedots {
    width: 360px;
  }
}

@media (min-width: 768px) {
  .hero-container {
    background-image: url("./assets/hero.svg");
  }
}

@media (max-width: 767px) {
  .hero-container {
    background-image: url("./assets/hero-mobile.svg");
  }

  .hero-sidedots {
    width: 195px;
  }

  .hero-logo-container {
    margin-bottom: 35px;
  }

  .hero-container {
    padding-top: 25px;
  }

  .hero-rocket-container {
    /*padding: 0px !important*/
  }
}

.hero-description {
  font-size: 24px;
  text-align: start;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
}

.hero-text {
  /*margin-bottom: 50px;*/
  margin-bottom: 10px;
}
