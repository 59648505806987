.textfield, .textarea {
  background: #FFFFFF; /*#FBFBFB;*/
  padding-left: 11px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;/*Montserrat medium*/
  color: #000000;
  position: relative;
  border: 1px solid #BECAD6;
  border-radius: 4px;/*9px;*/
  -webkit-transition-property: border-color;
   -moz-transition-property: border-color;
     -o-transition-property: border-color;
        transition-property: border-color;
  -webkit-transition-duration: 300ms;
   -moz-transition-duration: 300ms;
     -o-transition-duration: 300ms;
        transition-duration: 300ms;
    -webkit-transition-timing-function: ease;
     -moz-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
}

.ant-input, .ant-select-search__field {
  color: #000000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  background-color: #FBFBFB;
}

.ant-input:focus, .textfield:focus, .textarea:focus {
  border-color: #680894 !important;
}

.textfield:hover, .textarea:hover {
  border-color: #40a9ff;
}

.textfield:focus, .textarea:focus {
  outline: none;
}

.textfield {
  height: 40px;
}

.textfield-sm {
  height: 40px;
}

.textarea {
  height: 150px;
  padding-top: 17px;
}

.textfield-left-rounded {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.textfield-left-not-rounded {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

@media (min-width: 768px) {
  .textarea-lg, .textfield-lg, .textfield-container-lg {
    max-width: 400px;
    width: 100%;
  }

  .textarea-md, .textfield-md, .textfield-container-md {
    max-width: 300px;
    width: 100%;
  }

  .textarea-half, .textfield-half, .textfield-container-half {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .textarea-lg,
  .textfield-lg,
  .textfield-container-lg {
    width: 100%;
  }

  .textarea-md,.textfield-md, .textfield-container-md, .textfield-half, .textfield-container-half {
    width: 100%;
  }
}

.textfield-full, .textfield-container-full, .textarea-full {
  width: 100%;
  /*min-width: 300px;*/
}

.textfield-error {
  color: red;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}

.textfield-error-container {
  position: absolute;
}

.textfield-hidden {
  display: none;
}
