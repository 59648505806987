.login-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.login-container {
  width: 100%;
}

.login-container > *:nth-child(3) {
  margin-top: 30px;
}
