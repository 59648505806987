.post-wrapper {
  background-color: #FFFFFF;
}

.post-hero {
  width: 100%;
}

.post-authorwrapper {
  max-width: 880px;
  width: 100%;
  margin-top: 50px;
}

@media (min-width: 992px) {
  .post-hero {
    max-width: 960px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .post-hero {
    max-width: 1140px;
  }
}



.post-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.post-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.post-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 576px) {
  .post-container {
    max-width: 540px;
  }

  .post-about-restage-container {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .post-about-restage-container {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .post-container {
    max-width: 720px;
  }
}

@media (max-width: 991px) {
  .post-stickycontainer {
    display: none !important;
  }
}

.post-blockquote {
  padding-left: 15px;
  border-left: 6px solid #F2F2F2;
}

.post-about-restage-container {
  padding: 40px;
  background-color: #F2F2F2;
  border-radius: 9px;
  max-width: 880px;
  margin-bottom: 20px;
}

.post-about-restage-header {
  margin-bottom: 10px;
}

/*
@media (min-width: 992px) {
  .post-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .post-container {
    max-width: 1140px;
  }
}
*/
