.eventpackages-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventpackages > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventpackages-placements > *:not(:last-child) {
  border-bottom: 1px solid #E3DBE6;
}

.eventpackages-close-x {
  fill: #FBFBFB;
}
.eventpackages-close {
  background-color: #680894;
  border-radius: 100%;
  padding: 2px;
}
