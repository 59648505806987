.pill-wrapper > *:not(:last-child) {
  margin-right: 15px;
}

.pill-wrapper {
  /*padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;*/
  /*border: 1px solid #680894;*/

}

.pill-wrapper > *:not(:last-child) {
  margin-bottom: 15px;
}
