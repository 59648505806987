.search-sidepanel-audience-container {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .search-sidepanel-audience-container {
    padding-left: 15px;
  }

  .search-sidepanel-tabgroup-wrapper {
    padding-left: 15px;
  }

}

.search-sidepanel-tabgroup-wrapper {
  padding-right: 15px;
}
