.accordionpanel-body {
  overflow-y: hidden;
  width: 100%;
  border-left: 1px solid #BECAD6;
  border-right: 1px solid #BECAD6;
  transform-origin: top;
  height: auto;
  -webkit-transition-property: height;
   -moz-transition-property: height;
     -o-transition-property: height;
        transition-property: height;
  -webkit-transition-duration: 500ms;
   -moz-transition-duration: 500ms;
     -o-transition-duration: 500ms;
        transition-duration: 500ms;
    -webkit-transition-timing-function: ease;
     -moz-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
}

.accordionpanel-body > * {
  padding: 15px;
}

.accordionpanel-header {
  padding: 15px;
  border: 1px solid #BECAD6;
}

.accordionpanel-header:hover {
  cursor: pointer;
}

.accordionpanel-body.accordion-closed {
  height: 0px !important;
  border: none !important;
}

.accordionpanel-container {
  width: 100%;
}

.accordionpanel-icon-container {
  margin-right: 15px;
}

.accordionpanel-icon {
  width: 19px;
}

.accordionpanel-header.accordion-open {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
