@media (min-width: 768px) {
  .restage-event-audienceinfo-container {
    width: 24.895833vw;
    height: 20.8333vw;
    border-radius: 9px;
    padding: 2.0833vw;
  }

  .restage-event-audienceinfo-card {
    width: 100%;
    height: 100%;
  }
}

.restage-event-audienceinfo-card-wrapper {
  width: 100%;
  height: 100%;
}

.restage-event-audienceinfo-card-wrapper {
  /*transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease-out;
  will-change: transform;*/
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease-out;
  will-change: transform;
}

.restage-event-audienceinfo-card {
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  will-change: transform;
  position: absolute;
}


.restage-event-audienceinfo-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.restage-event-audienceinfo-container {
  position: relative;
  overflow: hidden;
}
