.expt-navbar-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 3;
  opacity: 0;
  padding-top: 20px;
  padding-left: 26px;
  padding-right: 26px;
  transition-duration: .25s;
  transition-property: opacity;
}

.expt-navbar-container:hover {
  opacity: 1;
}

.expt-logo {
  width: 3.64583vw;
}
