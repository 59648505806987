.eventsidepanel-wrapper > *:not(:last-child){
  margin-bottom: 25px;
}

.event-details-main {
  border-radius: 9px;
}

@media (min-width: 768px)  {
  .eventsidepanel {
    padding-left: 0px;
  }
  
  .eventsidepanel-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px)  {
  .eventsidepanel {
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px
  }

  .eventsidepanel-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
}


.eventsidepanel-container {
  border-radius: 9px;
  background-color: white;
}
