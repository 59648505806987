@media (min-width: 991px) {
  .pagesegment-row > *:first-child {
    padding-right: 50px;
  }
  /*.pagesegment-row > *:first-child {
    padding-right: 50px;
  }

  .pagesegment-row > *:last-child {
    padding-left: 50px;
  }

  .pagesegment-row-reverse > *:first-child {
    padding-left: 50px;
  }*/

  .pagesegment-row-reverse > *:first-child {
    padding-left: 50px;
  }
}

@media (min-width: 768px) {
  .pagesegment-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .pagesegment-row > *:not(:last-child), .pagesegment-row-reverse > *:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .pagesegment-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.pagesegment-container-white {
  background-color: #FFFFFF;
}

.pagesegment-container-purple {
  background: linear-gradient(to right, #680894, #3E0359);

}

.pagesegment-container-pink {
  background: linear-gradient(to right, #F8A59A, #EB5B49);
}

.pagesegment-header {
  margin-bottom: 10px;
}

.pagesegment-description {
  text-align: start;
  line-height: 151%;
}

.pagesegment-header {
  text-align: start;
  line-height: 32px;
}

.pagesegment-image {
  width: 100%;
  z-index: 3;
}

@media (min-width: 1200px) {
  .pagesegment-image {
    min-width: 595px;
  }
}
