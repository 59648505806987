@media (min-width: 768px) {
  .restage-button-group > *:not(:last-child) {
    margin-right: 7px;
  }
}

.restage-button-group-horizontal > *:not(:last-child) {
  margin-right: 7px;
}


@media (max-width: 767px) {
  .restage-button-group > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .restage-button-group {

  }
}

.restage-button-group {
  width: 100%;
}
