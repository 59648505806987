.services-container {
  background-image: url("./assets/dots.png"), linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-image: url("./assets/dots.png"), -webkit-linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-image: url("./assets/dots.png"), -moz-linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-image: url("./assets/dots.png"), linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-image: url("./assets/dots.png"), -o-linear-gradient(to top, #680894 40%, #FFFFFF 40%);
  background-position: top, 100%;
  background-size: cover, contain;
  background-attachment:initial;
  background-repeat: no-repeat;

}

.services-vine-container {
  padding: 20px;
  z-index: 1;
}


.services-description {

}

@media (min-width: 992px) {
  .services-wrapper {
    padding-right: 15px;
    padding-left: 15px;
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .services-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .services-wrapper {
    max-width: 1200px;
  }

  .services-left {
    position: relative;
    top: -99px;
    padding-bottom: 215px;
  }

  .services-right {
    position: relative;
    top: 46px;
    padding-bottom: 246px;
  }

  .services-vine-container {
    padding-left: 60px;
    padding-right: 157px;
    height: 825px;
    position: relative;

  }


  .services-vine {
    position: absolute;

  }
}

@media (min-width: 768px) {
  .services-details {

  }
  .services-vine {
    max-height: 800px;

  }
  .services-container {
    background-size: cover, contain;
  }
}

.services-container {
  background-image: linear-gradient(to right, #EB5B49, #680894);
  background-position: top, 100%;
  padding-top: 50px;
  background-attachment:initial;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .services-details {
    padding-bottom: 50px;
  }
  .services-vine {
    max-height: 600px;

  }

  .services-container {
    background-size: contain, contain;


  }

  .services-details > *:first-child {
    margin-bottom: 50px;
  }
}


.services-container {
  padding-top: 65px;
}

.services-details {
  z-index: 2;
}

.services-header {
  font-family: 'Poppins', sans-serif;
  line-height: normal;
  font-size: 40px !important;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 35px;

}
