.portfolioreview-details {
  padding-top: 15px;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .review-row > *:first-child {
    margin-bottom: 15px;
  }
}


@media (min-width: 768px) {
  .portfolioreview-image {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .portfolioreview-image {
    width: 100%;
  }
}
