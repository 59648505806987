.restage-event-documents {
    margin-top: 15px;
}

.restage-event-documents > *:not(:first-child) {
    margin-bottom: 10px;
}

.restage-event-documents > *:first-child {
    margin-bottom: 5px;
}
