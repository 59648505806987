.autocomplete-entry-group-button {
  height: 40px !important;
  min-height: 40px !important;
  border-radius: 4px;
  min-width: 170px;
}

@media (min-width: 992px)  {
  .autocomplete-entry-group-container > *:first-child {
    margin-right: 12px;
  }
}

@media (max-width: 991px)  {
  .autocomplete-entry-group-container > *:first-child {
    margin-top: 10px;
  }
}


.autocomplete-entry-group-container-vertical > *:first-child {
  margin-bottom: 10px;
}
