.navbar-container {
  height: 70px;
  background: linear-gradient(to right, #680894, #3E0359);
  z-index: 3 !important;
  position: relative;
}

.navbar-container-landing {
  background: transparent !important;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}


@media (min-width: 768px) {
  .navbar-container {
    padding-left: 35px;
    padding-right: 40px;
  }

  .navbar-links > *:not(:last-child){
    margin-right: 20px;
  }


  .navbar-mobile-link {
    display: none;
  }

  .navbar-tooltip-row > *:not(:last-child) {
    margin-right: 60px;
  }

}

@media (min-width: 1200px) {
  .navigation-links-menu-right {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .restage-user-link {
      display: none;
  }
  .navigation-links-right {
    display: none !important;
  }
  .navbar-links {
    display: none !important;
  }
}

@media (max-width: 767px) {

  .navbar-tooltip-resource-link {
      display: none;
  }

  .navbar-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-mobile-link {

  }
}

.navbar-logo {
  width: 166px;
}

.navbar-logo:hover {
  cursor: pointer;
}

.create-event-button {
  margin-right: 8px;
}

.ant-tooltip, .ant-tooltip-content {
  width: 100% !important;
  max-width: 630px !important;
}

.ant-tooltip-content {
  margin-left: 25px;
}

.ant-tooltip-inner {
  background-color: #E3DBE6 !important;
}

.ant-tooltip-arrow::before {
  background-color: #E3DBE6 !important;
}

.ant-tooltip-arrow {
  border-top-color: #E3DBE6 !important;
  border-left-color: #E3DBE6 !important;
}

.navbar-mobile-links {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow-y: 100%;
}

.navbar-mobile-link {
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-resources-submenu > .ant-collapse-header {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 0px !important;
}

.navbar-resources-submenu > .ant-collapse-content > .ant-collapse-content-box > *:not(:last-child) {
  margin-bottom: 15px;
}

.navbar-hidden {
  display: none !important;
}

.navbar-container-background-white {
    background-color: white !important;
    background-image: unset;
    border-bottom: 1px solid #F2F2F2;
}
