.expt-playlist-item-container {
  width: 18.23vw;
  padding: 1vw;
  background-color: rgba(51, 51, 51, .8);
}

.expt-playlist-button-icon {
  width: 1.25vw;
}

.expt-playlist-item-container:hover {
  cursor: pointer;
}
