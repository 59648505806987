.signup-container {
  height: 100%;
}

.signup-form > *:not(:last-child) {
  margin-bottom: 25px;
}

.signup-form > *:nth-child(5) {
  margin-top: 50px;
}

.signup-form {
  width: 100%;
}
