@media(min-width: 768px) and (max-width: 991px) {
  .banner-slider-container {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

@media(min-width: 992px) {
  .banner-slider-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}


@media(min-width: 576px) and (max-width: 767px) {
  .banner-slider-container {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}

@media(max-width: 575px) {
  .banner-slider-container {
    padding-top: 150px;
    padding-bottom: 275px;
  }

  .banner-slider-slide {
    padding-right: 34px;
    width: 100%;
  }

  .banner-container {
    position: relative;
    padding-left: 15px !important;
    padding-right: 15px !important;

  }

}

.banner-container {
  position: relative;
}

.home-dots {
  position: absolute;
  top: calc(50% - 60px);
  left: 5%;
}

.container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.home-wrapping-up-block {
  position: relative;
  padding-top: 125px;
  padding-bottom: 125px;
}

@media (min-width: 768px) {
  .home-wrapping-up-block {
    padding-right: 20% !important;
  }
}

.home-strikethrough-container {
  position: relative;
}

.home-strikethrough {
  height: 3px;
  left: 0px;
  right: 0px;
  position: absolute;
  background-color: #3E0359;
}
