.blog-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.blog-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.blog-tile:hover {
  cursor: pointer;
}
