.expand-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #680894;
}

.expand-container:hover {
  cursor: pointer;
}
.expand-children-container {
  background-color: white;
  position: absolute;
  padding: 15px;
  border: 1px solid #BECAD6;
  border-radius: 4px;
  z-index: 2;
}
/*.expand-children-container {
  background-color: white;
  position: absolute;
  min-width: 100%;
  height: 0%;
  -webkit-transition-property: height;
   -moz-transition-property: height;
     -o-transition-property: height;
        transition-property: height;
  -webkit-transition-duration: 500ms;
   -moz-transition-duration: 500ms;
     -o-transition-duration: 500ms;
        transition-duration: 500ms;
    -webkit-transition-timing-function: ease;
     -moz-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
}
*/
.expand-children-container-open {
  height: 100% !important;

}

@media (min-width: 768px) {
  .expand-children-container {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .expand-children-container {
    min-width: 100%;
  }
}
