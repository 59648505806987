@media (min-width: 992px) {
  .eventshome-container {
    padding-top: 30px;
    padding-bottom: 65px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .events-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .eventshome-container {
    padding-bottom: 65px;
  }
}

.eventshome-emptystate-instructions > *:not(:last-child) {
  margin-bottom: 25px
}


@media (min-width: 768px) {
  .eventshome-emptystate {
    padding-top: 20px;
  }
  .eventshome-emptystate-header, .eventshome-emptystate {
    margin-bottom: 60px;
  }

  .eventshome-emptystate-instructions {
    padding-left: 115px;
  }

  .eventshome-container > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }

  .eventshome-container {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.eventshome-emptystate {
  width: 100%;
  max-width: 1000px;
  min-height: 360px;
  background-position-x: calc(100% + 90px);
  background-position-y: 20px;
  background-image: url("assets/check.svg");
  background-repeat: no-repeat;
  background-color: rgba(235,91,73, .1);
  border-radius: 4px;
}

.eventshome-wrapper {
    padding-top: 30px;
}

.eventshome-emptystate-label {
  margin-right: 20px;
  width: 20px;
}

@media (max-width: 767px) {
  .eventshome-emptystate-instructions {
    padding-left: 30px;
    padding-right: 30px;
  }

  .eventshome-emptystate {
    padding: 20px;
  }
  .eventshome-emptystate-header, .eventshome-emptystate {
    margin-bottom: 30px;
  }

  .events-actions {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .eventshome-container {
    padding-top: 30px;

    padding-bottom: 35px;
  }

  .eventshome-container > *:not(:last-child) {
    margin-bottom: 30px;
  }
}
