@media (min-width: 768px) {
  .restage-form-group > *:not(:last-child) {
    margin-right: 30px;
  }

  .restage-form-group {

  }
}


@media (max-width: 767px) {
  .restage-form-group > *:not(:last-child) {
    margin-bottom: 30px;
  }

  .restage-form-group {

  }
}

.restage-form-group {
  width: 100%;
}



  /*Experiment Below*/
/*
  .restage-form-group:not(:last-child) {
    margin-bottom: 25px;
  }
*/
