.benefits-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./assets/shapes.png");
}

@media (min-width: 768px) {
  .benefits-container {
    background-position: 0 50%;
  }
}

@media (max-width: 767px) {
  .benefits-container {
    background-position: 0 50%;
  }
}

.benefits-container > *:first-child{
  margin-bottom: 120px;
}
