.body-copy-primary,
.body-copy-white,
.body-copy-error,
.body-copy-quarternary,
.body-copy-quintary,
.body-copy-tertiary-link,
.body-copy-secondary,
.body-copy-tertiary-bold,
.body-copy-white-semibold,
.body-copy-tertiary,
.body-copy-gray,
.body-copy-gray-4,
.body-copy-bold-tertiary-link,
.body-copy-brand-secondary {
  font-size: 16px;
}

.body-copy-brand-secondary {
  color: #828282;
}

.header-4-tertiary, .header-4-purple {
  font-size: 18px;
}


.restage-poppins {
  font-family: Poppins;
}

.restage-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.body-copy-xs-small-brand-secoundary-3 {
  color: #828282;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

.body-copy-tertiary-semibold, .body-copy-bold-light-gray, .body-copy-bold-orange, .body-copy-bold-orange, .body-copy-bold-tertiary, .body-copy-bold, .body-copy-bold-quintary, .body-copy-orange, .body-copy-orange-small, .body-copy-quarternary,  .body-copy-quintary  {
  font-family: 'Montserrat', sans-serif;
}

.body-copy-gray-4,
.body-copy-gray,
.body-copy-tertiary-semibold,
.body-copy-orange-small,
.body-copy-orange,
.body-copy-bold-orange,
.body-copy-bold-white,
.body-copy-bold-quintary,
.body-copy-bold-brand,
.body-copy-bold-light-gray,
.body-copy-bold,
.body-copy-white,
.body-copy-bold-tertiary,
.body-copy-bold-purple,
.body-copy-primary-small,
.body-copy-error-small,
.body-copy-secondary-small,
.body-copy-tertiary-small {
  font-size: 16px;
}
.body-copy-bold-tertiary-xs-small,
.body-copy-primary-xs-small,
.body-copy-error-xs-small,
.body-copy-secondary-xs-small,
.body-copy-tertiary-xs-small {
  font-size: 13px;
}

.body-copy-quintary,
.body-copy-quarternary,
.button-text,
.button-text-bold,
.button-text-medium,
.body-copy-bold-tertiary-xs-small,
.body-copy-error-xs-small,
.body-copy-error-small,
.body-copy-error,
.header-1-tertiary,
.header-4-tertiary,
.header-4-purple,
.body-copy-white,
.body-copy-bold-white,
.body-copy-tertiary,
.body-copy-gray,
.body-copy-gray-4,
.body-copy-white-semibold,
.body-copy-brand-secondary,
.body-copy-tertiary-link,
.body-copy-bold-tertiary-link,
.body-copy-tertiary-small,
.body-copy-tertiary-semibold,
.body-copy-primary,
.body-copy-primary-small,
.body-copy-secondary,
.body-copy-secondary-small {
  font-family: 'Montserrat', sans-serif;
}

.button-text-medium,
.body-copy-primary-xs-small,
.body-copy-error-xs-small,
.body-copy-error-small,
.body-copy-secondary-xs-small,
.body-copy-tertiary-xs-small,
.body-copy-white,
.body-copy-tertiary,
.body-copy-brand-secondary,
.body-copy-quarternary,
.body-copy-quintary,
.body-copy-tertiary-link,
.body-copy-gray,
.body-copy-gray-4,
.body-copy-tertiary-small,
.body-copy-tertiary-semibold,
.body-copy-primary,
.body-copy-primary-small,
.body-copy-secondary,
.body-copy-secondary-small {
  font-weight: 500;
}

.body-copy-bold-white, .body-copy-bold-tertiary-xs-small, .header-4-purple, .header-4-tertiary, .button-text-bold, .body-copy-bold-tertiary-link, .body-copy-bold-orange, .body-copy-bold-tertiary, .body-copy-bold, .body-copy-bold-quintary, .body-copy-bold-purple, .body-copy-bold-brand, .body-copy-bold-light-gray {
  font-weight: 700;
}

.link-brand-white,
.link-brand-secondary,
.button-text-red,
.button-text,
.button-text-bold,
.button-text-medium {
  font-size: 14px;
}

.header-3-tertiary, .header-3-purple, .header-3-white {
  font-size: 24px;
}

.link-brand-white,
.link-brand-secondary,
.button-text-red,
.header-3-tertiary,
.header-3-purple,
.header-3-white,
.header-2-brand,
.header-2-darkpurple,
.header-2-white,
.header-2-orange,
.header-2-tertiary,
.header-2-purple,
.header-2-mid-purple,
.header-2-quarternary,
.header-2-tertiary,
.header-2-secondary,
.header-2-gray,
.header-1-purple,
.header-1-white,
.header-1-tertiary-semibold,
.body-copy-tertiary-semibold {
  font-family: 'Poppins', sans-serif;
}

.header-2-mid-purple,
.link-brand-white,
.link-brand-secondary,
.header-2-brand,
.header-2-orange,
.header-2-tertiary,
.header-2-white,
.header-2-purple,
.header-2-darkpurple,
.header-2-quarternary,
.header-2-tertiary,
.header-2-secondary,
.header-2-gray,
.body-copy-white-semibold,
.header-1-tertiary-semibold,
.body-copy-tertiary-semibold  {
  font-weight: 600;
}

.body-copy-bold-quintary {
  color: #F8A59A;
}

.body-copy-bold-purple {
  color: #3E0359;
}

.restage-font-3E0359 {
  color: #3E0359;
}

.body-copy-quintary {
  color: #828282;
}

.body-copy-bold-light-gray {
  color: #F8F8F8;
}


.body-copy-gray-4 {
  color: #BDBDBD;
}

.header-1-tertiary, .header-1-purple, .header-1-white {
  font-weight: 300;
}

.header-2-quarternary, .body-copy-bold, .body-copy-quarternary {
  color: #3E0359;
}

.body-copy-primary,
.body-copy-primary-small {
  color: #FBFBFB;
}

.header-1-white,
.header-3-white,
.body-copy-white-semibold,
.link-brand-white,
.body-copy-bold-white,
.body-copy-white {
  color: #FFFFFF;
}

.header-4-purple,
.header-3-purple,
.header-1-purple,
.header-2-mid-purple,
.body-copy-bold-brand,
.body-copy-secondary,
.link-brand-secondary,
.body-copy-tertiary-link,
.body-copy-secondary-small {
  color: #680894 !important;
}

.header-2-orange, .body-copy-bold-orange, .body-copy-orange, .body-copy-orange-small  {
  color: #EB5B49;
}

.header-2-midpurple {
  color: #3E0359;
}

.restage-font-333333,
.header-4-tertiary,
.header-3-tertiary,
.header-2-tertiary,
.header-1-tertiary,
.header-1-tertiary-semibold,
.body-copy-tertiary,
.body-copy-bold-tertiary-xs-small,
.body-copy-bold-tertiary,
.body-copy-bold-tertiary-link,
.body-copy-tertiary-small {
  color: #333333;
}

.header-1-white, .header-1-purple, .header-1-tertiary, .header-1-tertiary-semibold {
  font-size: 40px;
}

.header-2-darkpurple,
.header-2-gray,
.header-2-mid-purple,
.header-2-white,
.header-2-brand,
.header-2-orange,
.header-2-secondary,
.header-2-tertiary,
.header-2-quarternary,
.header-2-purple  {
  font-size: 28px;
}

.header-2-gray, .body-copy-gray {
  color: #F2F2F2;
}

.header-2-white,
.header-2-secondary  {
  color: white;
}

.header-2-brand,
.header-2-purple {
  color: #3E0359;
}

.header-2-darkpurple {
  color: #3E0359;
}

.header-2 {

}

.header-3-tertiary, .header-3-purple, .header-3-white {
  font-weight: 400;
}

.body-copy-tertiary-link {

}

.link-brand-white,.link-brand-secondary {
  letter-spacing: 1px;
}

.link-brand-secondary:hover,
.body-copy-bold-tertiary-link:hover,
.body-copy-tertiary-link:hover {
  cursor: pointer;
}

.body-copy-bold-tertiary-link {
  text-decoration: underline !important;
}

.restage-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  transition: color .200s;
  cursor: pointer;
}

.restage-link:hover {
  color: #F8A59A !important;
}

.restage-link:hover > * {
  color: #F8A59A !important;
}

.restage-link:hover > * > path {
  transition: fill 250ms
}

.restage-link:hover > * > path {
  fill: #F8A59A !important;
}

.restage-link:hover > * {
  transition: color 250ms
}

.field-required,
.body-copy-error,
.body-copy-error-small,
.body-copy-error-xs-small {
  color: red;
}

.body-copy-error-container {
  text-align: center;
  position: absolute;
}

.body-copy-error-wrapper {
  position: relative;
}

.button-text, .button-text-red {
  font-weight: 500;
}

.button-text-red {
  background-color: #EB5B49;
  letter-spacing: 1px;
}

.link-undecorated:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .restage-font-96 {
    font-size: 5vw;
  }

  .restage-font-64 {
    font-size: 3.33333vw;
  }

  .restage-font-48 {
    font-size: 2.5vw;
  }

  .restage-font-45 {
    font-size: 2.291vw;
  }

  .restage-font-36 {
    font-size: 1.875vw;
  }

  .restage-font-30 {
    font-size: 1.5625vw;
  }
  .restage-font-32 {
    font-size: 1.666vw;
  }
  .restage-font-33 {
    font-size: 1.718vw;
  }


  .restage-font-28 {
    font-size: 1.458333vw;
  }

  .restage-font-24 {
    font-size: 1.25vw;
  }

  .restage-font-20 {
    font-size: 1.041vw;
  }


  .restage-font-18 {
    font-size: 0.9375vw;
  }

  .restage-font-16 {
    font-size: 0.83333vw;
  }

  .restage-font-16 {
    font-size: 0.72916vw;
  }
}

.restage-font-bold {
  font-weight: bold;
}

.restage-font-medium {
  font-weight: 500;
}

.restage-font-semibold {
  font-weight: 600;
}

.restage-font-white {
  color: white;
}

.restage-font-purple-gray {
  color: #E3DBE6;
}

.restage-font-deep-purple {
  color: #3E0359;
}

.restage-font-brand-secondary {
  color: #333333;
}

.restage-font-semibold  {
  font-weight: 600;
}

.restage-text-align-start {
  text-align: start;
  text-align: left;
}
