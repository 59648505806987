.tab-container {
  padding-bottom: 15px;
  padding-top: 15px;
}

.tab-container:hover {
  cursor: pointer;
}

@media (max-width: 767px) {
  .tab-label {
    /*display: none;*/
  }

  .tab-icon {
    margin: auto;
  }
}

@media (min-width: 768px) {
  .tab-label {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .tab-container {

  }
  .tab-label {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.tab-container.inactive {
}

.tab-container.active {

}

.tab-icon {
 height: 22px;
}

.tab-checkmark {
  width: 16px !important;
}
