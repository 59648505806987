.search-container {
  /*padding: 15px;*/
}


.search-row > *:not(:last-child) {

}

@media (min-width: 992px) {
  .search-events {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }

  .search-events > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-events {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }

  .search-events > * {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .search-events {
    width: 100%;
    margin-bottom: -15px;
  }

  .search-events > * {
    margin-bottom: 30px;
  }

  .search-wrapper {
    padding-top: 30px;
  }
}

/*@media (min-width: 1200px) {
  .search-container {
    max-width: 1400px;
  }
}*/



.search-filter {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .search-filter > *:not(:last-child){
    margin-right: 15px;
  }

  .search-wrapper {
    padding-top: 50px;
  }

  .search-form {
    border-right: 1px solid #BECAD6;
  }
}

.search-form {
  padding-right: 0px !important;
}
