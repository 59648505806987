.footer-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-container-pink {
  background: linear-gradient(45deg, #EB5B49, #F8A59A);
}

.footer-container-purple {
  background: linear-gradient(45deg, #680894, 65%, #E3DBE6);
}

.footer-main {
  background-color: rgba(227, 219, 230, .25);
  height: 100%;
  border-radius: 9px;
}

@media (min-width: 992px) {
  .footer-main {
    width: 85%;
  }
}

@media (min-width: 768px) {
  .footer-main {
    padding: 35px 45px 80px 45px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .footer-main {
    padding: 35px 40px 80px 40px;
  }
}

@media (max-width: 991px) {
  .footer-main {
    width: 100%;
  }


  .footer-ctas-slot {
    margin-top: 35px;
  }
}

@media (min-width: 576px) {
  .footer-top-row {
    margin-right: 45px;
  }
}


@media (max-width: 575px) {
  .footer-main {
    padding: 15px 45px 80px 15px;
  }

  .footer-top-row {
    margin-bottom: 45px;
  }
}
