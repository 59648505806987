@media (min-width: 768px) {
  .createaccount-modal-container {
    padding-left: 125px;
    padding-right: 125px;
    height: 100%;
  }

  .createaccount-modal-form-wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.createaccount-modal-item-visible {
  display: none !important;
}

.createaccount-modal-confirmation-image {
  width: 100%;
  max-width: 250px;
  margin-bottom: 30px;
}
