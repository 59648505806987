

.intro-container > *:not(:last-child) {
  margin-bottom: 35px;
}

.intro-reasons > *:not(:last-child) {
  margin-bottom: 15px;
}

.intro-container {
  padding-left: 0px !important;

}

.intro-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}


.intro-details > *:not(:last-child) {
  margin-bottom: 25px;
}

.intro-circle {
  background-color: #EB5B49;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 25px;
}

@media (min-width: 992px) {
  .intro-image-sm {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .intro-image-sm {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .intro-image-md {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .intro-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .intro-content {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
  .intro-container {
    padding-top: 25px !important;
    padding-bottom: 0px;
    padding-right: 45px;
    padding-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .intro-image {
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .intro-image {
    display: none;
  }

  .intro-content {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
  }

  .intro-container {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }

  .intro-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
