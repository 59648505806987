.blogtile-container {
  border-radius: 5px;
  -webkit-transition: box-shadow .5s;
  -o-transition: box-shadow .5s;
  -moz-transition: box-shadow .5s;
  transition: box-shadow .5s;
  overflow: hidden;
  height: 500px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.blogtile-container:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.blogtile-container {
  padding-left: -15px;
  padding-right: -15px;
}

@media (min-width: 576px) {
  .blogtile-container {
    width: calc(6/12 * 100% - 30px);
  }
}

@media (min-width: 1200px) {
  .blogtile-container {
    width: 350px;
  }
}

@media (max-width: 575px) {
  .blogtile-container {
    width: 100%;
  }
}

.blogtile-container:hover {
  cursor: pointer;
}

.blogtile-footer {
  border-top: 1px solid #F2F2F2;
}

.blogtile-footer, .blogtile-content {
  padding: 20px;
}

.blogtile-poster-container {
  padding: 0px !important;
}


.blogtile-poster {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 200px;
}
