.objectives-option-container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  transition-property: color;
  transition-duration: .25s;
}

.objectives-option-container:hover {
  cursor: pointer;
  color: #EB5B49 !important;
}
