.eventtile-header {

}

.eventtile-footer {
  border-top: 1px solid #F2F2F2;
}

.eventtile-content, .eventtile-footer {
  padding: 20px;
}

.eventtile-container:hover {
  cursor: pointer;

}

.eventtile-container {
  border-radius: 5px;
  -webkit-transition: box-shadow .5s;
  -o-transition: box-shadow .5s;
  -moz-transition: box-shadow .5s;
  transition: box-shadow .5s;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 992px) {
  .eventtile-container {
    width: calc(6/12 * 100% - 30px) ;
    padding-left: -15px;
    padding-right: -15px;
  }

  /*.eventtile-container > * {
    padding-left: 15px;
    padding-right: 15px;
  }*/
}

@media (min-width: 768px) and (max-width: 991px) {
  .eventtile-container {
    width: calc(6/12 * 100% - 30px);
  }
}

@media (max-width: 767px) {
  .eventtile-container {
    width: 100%;
  }
}

.eventtile-container:hover {
  cursor: pointer;
}

.eventtile-poster-container {
  padding: 0px !important;
}

.eventtile-poster {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 200px;
}
