.cart-footer-container {
  height: 85px;
  background-color: #FBFBFB;
  position: fixed;
  bottom: 0;
  text-align: right;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #BECAD6;
}

.cart-footer {
  height: 100%;
}

.footer-purchase {
  padding: 24px !important;
  overflow-y: scroll;
  margin-bottom: 111px;
  /*padding-top: 45px;*/
}

.footer-placements > *:not(:last-child) {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .checkout-modal-form-wrapper {
    height: 100%;
  }

  .checkout-modal-footer-sidepanel {
    border-left: 1px solid #e8e8e8
  }
}

@media (min-width: 576px) {
  .footer-column-1 {
    margin-right: 45px;
  }
}

@media (max-width: 575px) {
  .footer-column-1 {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .footer-modal-side-panel {
    padding-top: 45px;
  }

}

.footer-modal-side-panel {
  width: 100%;
}

.footer-modal-selected-placement {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer-modal-selected-placements > *:not(:last-child) {
  margin-bottom: 15px;
}

.footer-wrapper {
  z-index: 3;
  position: relative;
}

.footer-signup-container {
  margin-bottom: 75px;
  text-align: center;
}
