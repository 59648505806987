.subscribe-container {
  background-image: linear-gradient(to right, #680894, #3E0359);
  height: 500px;
  padding: 20px;
  position: relative;
}

@media (min-width: 576px) {
  .subscribe-container {
    width: calc(6/12 * 100% - 30px);
  }
}

@media (min-width: 1200px) {
  .subscribe-container {
    width: 350px;
  }
}

@media (max-width: 575px) {
  .subscribe-container {
    width: 100%;
  }
}

.subscribe-bubbles-top, .subscribe-bubbles-bottom {
  position: absolute;
  z-index: 1;
  height: 220px;
}


.subscribe-bubbles-top {
  top: 0px;
  left: 0px;
}

.subscribe-bubbles-bottom {
  right: 0px;
  bottom: 0px;
}
