.restage-divider {
  height: 1px;
  width: 100%;
}

.restage-divider-gradient {
  background: radial-gradient(closest-side, #680894, #EB5B49);
}

.restage-divider-brand-secondary {
  background-color: #828282;
}

.restage-divider-brand {
  background-color: #E3DBE6;
}
