.restage-form {
  padding-left: 0px !important;
}

.restage-side-panel {
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .restage-side-panel {
    margin-bottom: 50px;
    padding-left: 0px !important;
  }

  .restage-form {
    padding-right: 0px !important;
  }
}

.restage-side-panel {
  padding-right: 0px !important;
}

@media (min-width: 768px) {
  .restage-side-panel-docked {
    right: 0px;
    top: 85px;
    position: fixed;
    bottom: 0px;
  }
}

/*
.restage-form > *:not(:last-child) {
  margin-bottom: 25px;
}*/
