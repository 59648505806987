.home-organizers-cards > * {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.home-organizers-cards {
  margin-left: -15px;
  margin-right: -15px;
}

.organizers-floating-img {
  position: absolute;
  z-index: 2;
  right: 0;
}

.organizers-floating-grid {
  position: absolute;
  z-index: 1;
  width: 285px;
  right: 30px;
  top: -111px;
}

.organizers-floating-freckles {
  position: absolute;
  z-index: 1;
  width: 75px;
  left: 0px;;
  top: -126px;
}

.organizers-floating-dot {
  position: absolute;
  z-index: 1;
  width: 23px;
  left: 20%;
  bottom: 10px;
}

.organizers-label {
  margin-bottom: 30px;
}


.organizers-persona-tile {
  width: 200px;
  height: 200px;
  border: 3px solid #FBFBFB;
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -webkit-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -moz-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: linear-gradient(to bottom right, #F8A59A, #EB5B49);
  background-image: -o-linear-gradient(to bottom right, #F8A59A, #EB5B49);
  border-radius: 28px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: #FBFBFB;
}

.home-organizers-container {
  position: relative;
  z-index: 2;
}


@media (min-width: 768px) {

  .home-organizers-container {
    padding-bottom: 65px !important;
  }

  .home-organizers-header {
    margin-bottom: 65px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .organizers-floating-img {
    width: 400px;
  }
}


@media (max-width: 767px) {
  .home-organizers-container {
    padding-top: 75px !important;
  }

  .home-organizers-header {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .organizers-floating-img {
    width: 500px;
  }

  .home-organizers-container {
    padding-bottom: 200px !important;
  }
}

@media (max-width: 575px) {
  .organizers-floating-img {
    bottom: -45px;
  }

  .organizers-floating-img {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .home-organizers-container {
    padding-bottom: 200px !important;
    padding-top: 75px !important;
  }
}

@media (min-width: 1200px) {
  .organizers-floating-img {
    width: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .organizers-floating-img {
    width: 400px;
  }
}

@media (min-width: 992px) {
  .home-organizers-dots {
    padding-bottom: 60px;
  }

  .home-organizers-cta {
    margin-top: 65px;
  }
}

@media (max-width: 991px) {
  .home-organizers-dots {
    padding-bottom: 230px;
  }

  .home-organizers-cta {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
  }

  .home-organizers-image-container {
    margin-bottom: 35px;
  }
}

.home-organizers-header {
  max-width: 600px;
}

@media (min-width: 576px) {
  .organizers-floating-img {
    bottom: -80px;
  }

  .organizers-sections-container {
    width: 550px;
  }
}

.home-organizers-image-container > img {
  width: 200px;
}

.home-organizers-dots {
  background-image: linear-gradient(to bottom, #EB5B49, #C24C3D);
  position: relative;
}


.actions-label {
  font-size: 40px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.actions-description {
  font-size: 20px;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

@media (max-width: 575px) {
  .organizers-sections-container > *:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .home-organizers-dots {
    padding-top: 80px;
  }
  .actions-header {
    margin-top: 95px;
  }


}

@media (max-width: 767px) {
  .home-organizers-dots {
    padding-top: 40px;
  }
  .actions-header {
    margin-top: 65px;
  }
}

.home-organizers-cards {

}

.home-organizers-how {
  padding-left: 0px !important;
}
