.sponsors-section-img {
  height: 75px;
}

.sponsors-section-description {
  text-align: start;
}

@media (min-width: 992px) {
  .sponsors-section-container {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .sponsors-section-container {
    width: 220px;
  }
}
