.eventdetails-container > *:not(:last-child) {
  margin-bottom: 25px;
}

.eventdetails-container {
  margin-bottom: 50px;
}

.eventdetails-inputfile {
  display: none;
}

.eventdetails-upload > *:not(:last-child){
  margin-bottom: 25px;
}
