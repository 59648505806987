.learnmore-hero-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  padding-top: 100px;
  padding-bottom: 100px
}

@media (min-width: 992px) {
  .learnmore-hero-container {
    height: calc(100vh - 70px);
    min-height: 600px;
  }
}


.learnmore-hero-button-container {
  margin-top: 60px;
}
