.negotiations {
  padding: 0%;
}

.negotiations-modal {
  height: 100%;
}


.negotiations-wrapper {
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
}

@media (min-width: 768px) {
  .negotiations-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .negotiations-footer {
    padding-bottom: 30px;
  }

  .negotiations-footer {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .negotiations-wrapper {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .negotiations-footer {
    padding-bottom: 15px;
  }

  .negotiations-footer {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .negotiations-footer {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.negotiations-messages {
  overflow-y: scroll;
  padding-bottom: 204px;
}

.negotiations-footer {
  background-color: #F8F8F8;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.negotiations-modal-body {
  padding-left: 30px;
  margin-right: 30px;
  padding-bottom: 131px;
}

.negotiations-package-name {
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #680894;
  padding-top: 30px;
}

.negotiations-package-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 151.1%;
  padding-right: 6%;
  padding-top: 10px;
}

.negotiations-negotiate-pacakge {
  padding-top: 20px;
}

.negotiations-divider {
  height: 1px;
  background-color: #E3DBE6;
  margin-top: 30px;
}

.negotiations-sub-container {
  padding-top: 10px;
}

.negotiations-button-group {
  background: #F2F2F2;
  border-radius: 0px 0px 8px 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
