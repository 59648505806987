.customize-package-modal-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 100%;
  overflow-y: scroll;
}

.customize-package-modal-form > *:not(:last-child) {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .customize-package-modal-reset-button {
    margin-bottom: 30px;
  }
}
